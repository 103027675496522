<template>
  <!-- eslint-disable  max-len -->
  <svg
    :fill="fill"
    class="no-margin-svg open-eye-svg"
    width="21"
    height="14"
    viewBox="0 0 21 14"
  >
    <path
      :fill="fill"
      d="M10.5 0C5.72727 0 1.62273 2.89333 0 7C1.62273 11.1067 5.72727 14 10.5 14C15.2727 14 19.3773 11.1067 21 7C19.3773 2.89333 15.2727 0 10.5 0ZM10.5 11.6667C7.82727 11.6667 5.72727 9.61333 5.72727 7C5.72727 4.38667 7.82727 2.33333 10.5 2.33333C13.1727 2.33333 15.2727 4.38667 15.2727 7C15.2727 9.61333 13.1727 11.6667 10.5 11.6667ZM10.5 4.2C8.87727 4.2 7.63636 5.41333 7.63636 7C7.63636 8.58667 8.87727 9.8 10.5 9.8C12.1227 9.8 13.3636 8.58667 13.3636 7C13.3636 5.41333 12.1227 4.2 10.5 4.2Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#000',
    },
  },
};
</script>

 <style lang="scss" scoped>
  .open-eye-svg {
    width: 21px; // ie11 support
    height: 14px; // ie11 support
  }
</style>
