export const STORE_URL = {
  ios: 'https://apps.apple.com/fr/app/skello/id1215389131',
  android: 'https://play.google.com/store/apps/details?id=app.skello.skello',
};

export const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

export const isAndroid = () => /Android/i.test(navigator.userAgent);

export const isMobile = () => isIOS() || isAndroid();
