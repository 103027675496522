<template>
  <!-- eslint-disable  max-len -->
  <svg
    fill="none"
    class="no-margin-svg sk-large-s-svg"
    width="208"
    height="350"
    viewBox="0 0 208 350"
  >
    <path
      :fill="fill"
      d="M191.857 177.286L147.546 135.181L186.216 98.3882L190.472 94.3434C201.096 83.8201 206.957 69.7606 206.8 55.1792C206.643 40.5978 200.479 26.6554 189.631 16.341C178.784 6.02673 164.115 0.161661 148.769 0.00329213C133.424 -0.155077 118.625 5.40586 107.543 15.4939L64.566 56.3319L15.9985 102.677C5.75442 112.418 0 125.626 0 139.397C0 153.168 5.75442 166.376 15.9985 176.117L60.1041 218.222L21.5886 254.82C10.5848 265.276 4.40286 279.458 4.40286 294.245C4.40286 309.032 10.5848 323.214 21.5886 333.67C32.5925 344.126 47.5169 350 63.0787 350C78.6405 350 93.565 344.126 104.569 333.67L191.754 250.824C196.849 246.005 200.893 240.279 203.656 233.973C206.419 227.668 207.846 220.906 207.856 214.076C207.865 207.245 206.457 200.48 203.712 194.167C200.966 187.854 196.938 182.118 191.857 177.286Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#fff',
    },
  },
};
</script>

 <style lang="scss" scoped>
  .sk-large-s-svg {
    width: 208px; // ie11 support
    height: 350px; // ie11 support
  }
</style>
