<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="420"
    height="424"
    viewBox="0 0 420 424"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="210"
      cy="210"
      r="210"
      fill="white"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="4"
      width="420"
      height="420"
    >
      <circle
        cx="210"
        cy="214"
        r="210"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect
        x="233.718"
        y="193.687"
        width="14.5147"
        height="18.8383"
        rx="7.25736"
        transform="rotate(-45 233.718 193.687)"
        fill="#ecf0f1"
      />
      <rect
        x="375.633"
        y="332.308"
        width="14.5147"
        height="83.5852"
        rx="7.25736"
        transform="rotate(-45 375.633 332.308)"
        fill="#ecf0f1"
      />
      <path
        d="M394.154 319.743L371.565 342.332L351.236 326.518L378.342 299.414L394.154 319.743Z"
        fill="#56626c"
      />
      <path
        d="M240.554 211.319L263.143 188.73L283.472 204.545L256.366 231.648L240.554 211.319Z"
        fill="#56626c"
      />
      <path
        d="M257.98 230.035L281.859 206.156C286.589 201.426 294.168 201.146 299.235 205.516L333.165 231.649L283.473 281.341L257.337 247.411C252.97 242.346 253.25 234.765 257.98 230.035Z"
        fill="#000000"
        fill-opacity="0.8"
      />
      <path
        d="M265.402 207.27C264.704 207.27 264.006 207.002 263.474 206.471C262.408 205.405 262.408 203.682 263.474 202.616L271.004 195.084C272.064 194.02 273.793 194.02 274.859 195.084C275.922 196.15 275.922 197.875 274.859 198.938L267.329 206.471C266.797 207.002 266.1 207.27 265.402 207.27Z"
        fill="#000000"
      />
      <path
        d="M248.837 223.835C248.139 223.835 247.441 223.567 246.909 223.036C245.843 221.97 245.843 220.247 246.909 219.181L254.439 211.649C255.5 210.585 257.228 210.585 258.294 211.649C259.357 212.715 259.357 214.44 258.294 215.503L250.764 223.036C250.232 223.567 249.534 223.835 248.837 223.835Z"
        fill="#000000"
      />
      <path
        d="M283.472 281.342L333.167 231.647C335.661 229.153 339.707 229.153 342.202 231.647C344.696 234.144 344.696 238.19 342.202 240.684L292.506 290.377C290.012 292.871 285.969 292.871 283.471 290.377C280.977 287.882 280.977 283.837 283.472 281.342Z"
        fill="#000000"
      />
      <path
        d="M284.237 235.402L287.225 232.412C293.885 225.754 304.683 225.754 311.341 232.412L321.872 242.943L294.765 270.049L284.237 259.521C277.577 252.858 277.577 242.06 284.237 235.402Z"
        fill="#9ea7a9"
      />
      <path
        d="M361.778 336.776C361.08 336.776 360.383 336.509 359.851 335.977C358.785 334.911 358.785 333.188 359.851 332.122L367.381 324.59C368.441 323.527 370.17 323.527 371.236 324.59C372.299 325.656 372.299 327.381 371.236 328.445L363.706 335.977C363.174 336.509 362.476 336.776 361.778 336.776Z"
        fill="#000000"
      />
      <path
        d="M378.343 320.212C377.645 320.212 376.947 319.945 376.416 319.413C375.35 318.347 375.35 316.624 376.416 315.558L383.945 308.026C385.006 306.963 386.734 306.963 387.8 308.026C388.864 309.092 388.864 310.817 387.8 311.881L380.27 319.413C379.739 319.945 379.041 320.212 378.343 320.212Z"
        fill="#000000"
      />
      <path
        d="M351.237 249.72L301.542 299.415C299.047 301.909 295.002 301.909 292.507 299.415C290.013 296.918 290.013 292.872 292.507 290.378L342.202 240.685C344.697 238.191 348.74 238.191 351.237 240.685C353.732 243.18 353.732 247.225 351.237 249.72Z"
        fill="#9ea7a9"
      />
      <path
        d="M376.729 301.027L352.85 324.906C348.12 329.635 340.541 329.916 335.473 325.546L301.544 299.413L351.236 249.72L377.372 283.65C381.739 288.716 381.459 296.297 376.729 301.027Z"
        fill="#000000"
      />
      <path
        d="M350.964 295.167L346.989 299.142C340.602 305.53 330.245 305.53 323.858 299.142L312.836 288.12L339.942 261.014L350.964 272.036C357.352 278.423 357.352 288.78 350.964 295.167Z"
        fill="#717679"
      />
      <g clip-path="url(#clip0)">
        <path
          d="M165.818 204.483C164.933 205.368 163.507 205.476 162.494 204.688C158.107 201.275 156.2 198.351 154.669 196.001C153.08 193.561 152.119 192.087 148.872 190.802C147.581 190.291 146.949 188.826 147.458 187.534C147.969 186.239 149.436 185.609 150.726 186.12C155.463 187.995 157.128 190.549 158.888 193.253C160.284 195.392 161.864 197.82 165.586 200.713C166.683 201.567 166.881 203.15 166.026 204.246C165.96 204.33 165.893 204.408 165.818 204.483Z"
          fill="#f0900d"
        />
        <path
          d="M162.259 208.039C161.657 208.64 160.826 208.874 160.048 208.74C154.797 208.106 152.358 205.451 150.204 203.104C148.175 200.893 146.422 198.984 142.137 198.038C140.779 197.738 139.923 196.394 140.222 195.037C140.521 193.681 141.866 192.823 143.223 193.122C149.058 194.411 151.751 197.343 153.914 199.698C155.867 201.825 157.276 203.363 160.756 203.752C161.324 203.818 161.851 204.071 162.255 204.475C163.239 205.459 163.241 207.056 162.259 208.039Z"
          fill="#d03e50"
        />
        <path
          d="M169.381 200.92C168.398 201.903 166.805 201.904 165.821 200.92C163.516 198.615 163.167 195.966 162.832 193.403C162.396 190.083 161.903 186.322 156.92 181.34C155.936 180.355 155.938 178.762 156.92 177.78C157.903 176.797 159.496 176.796 160.48 177.78C166.673 183.973 167.337 189.044 167.825 192.75C168.125 195.059 168.334 196.314 169.38 197.36C170.365 198.345 170.363 199.938 169.381 200.92Z"
          fill="#188377"
        />
        <path
          d="M144.461 179.559C143.772 180.248 142.714 180.5 141.753 180.12C137.017 178.245 135.352 175.691 133.594 172.989C132.196 170.847 130.617 168.421 126.893 165.527C125.797 164.672 125.599 163.09 126.452 161.995C127.308 160.911 128.885 160.698 129.985 161.552C134.375 164.966 136.278 167.891 137.811 170.239C139.4 172.679 140.361 174.153 143.606 175.44C144.899 175.949 145.531 177.414 145.02 178.708C144.89 179.037 144.696 179.324 144.461 179.559Z"
          fill="#f0900d"
        />
        <path
          d="M151.582 172.438C150.994 173.025 150.129 173.31 149.257 173.118C143.424 171.831 140.731 168.899 138.566 166.542C136.615 164.417 135.205 162.879 131.725 162.489C131.158 162.423 130.629 162.169 130.226 161.766C129.242 160.782 129.24 159.185 130.223 158.203C130.83 157.596 131.67 157.361 132.455 157.505C137.69 158.144 140.127 160.795 142.277 163.137C144.307 165.348 146.058 167.256 150.343 168.201C151.699 168.5 152.559 169.844 152.258 171.202C152.151 171.687 151.909 172.11 151.582 172.438Z"
          fill="#d03e50"
        />
        <path
          d="M135.56 188.46C134.577 189.442 132.984 189.444 132 188.46C125.807 182.267 125.143 177.196 124.655 173.49C124.355 171.181 124.146 169.926 123.1 168.879C122.115 167.895 122.117 166.302 123.1 165.319C124.082 164.337 125.675 164.335 126.66 165.319C128.965 167.625 129.314 170.273 129.648 172.836C130.084 176.156 130.578 179.917 135.56 184.9C136.544 185.884 136.542 187.477 135.56 188.46Z"
          fill="#188377"
        />
        <path
          d="M245.947 188.484L238.827 195.605C237.844 196.587 236.251 196.589 235.267 195.605L151.604 111.942C145.714 106.052 136.134 106.052 130.244 111.942L119.563 122.622C113.673 128.513 113.673 138.093 119.563 143.983L133.779 158.199C134.763 159.183 134.761 160.776 133.779 161.758L126.659 168.879C125.676 169.861 124.083 169.863 123.099 168.879L108.883 154.663C97.1047 142.885 97.1047 123.721 108.883 111.942L119.564 101.262C131.342 89.4834 150.506 89.4834 162.285 101.262L245.947 184.924C246.931 185.909 246.93 187.502 245.947 188.484Z"
          fill="#ecf0f1"
        />
        <path
          d="M181.888 252.588L176.548 257.929C164.769 269.707 145.605 269.707 133.827 257.929L128.417 252.519C127.433 251.534 127.435 249.942 128.417 248.959L135.537 241.839C136.52 240.856 138.113 240.855 139.097 241.839L144.484 247.225C150.397 253.139 159.977 253.139 165.868 247.248L171.208 241.908C177.098 236.018 177.098 226.438 171.208 220.548L158.724 208.064C157.742 207.082 157.742 205.487 158.724 204.504L165.845 197.384C166.827 196.401 168.422 196.401 169.404 197.384L181.888 209.867C193.666 221.646 193.666 240.81 181.888 252.588Z"
          fill="#ecf0f1"
        />
        <path
          d="M89.2558 177.757L64.335 202.678L46.5346 184.877C44.5695 182.912 44.5695 179.722 46.5346 177.757L64.335 159.957C66.3001 157.992 69.4901 157.992 71.4552 159.957L89.2556 177.757L89.2558 177.757Z"
          fill="#d4dbdf"
        />
        <path
          d="M139.097 220.478L107.056 252.519C106.074 253.502 104.481 253.503 103.496 252.519L60.7751 209.798C59.7906 208.813 59.7925 207.221 60.7751 206.238L92.8159 174.197C93.7985 173.215 95.3916 173.213 96.3759 174.197L139.097 216.918C140.082 217.902 140.08 219.495 139.097 220.478Z"
          fill="#ecf0f1"
        />
        <path
          d="M139.097 241.839L128.417 252.519C127.434 253.501 125.841 253.503 124.857 252.519L115.957 243.619L130.197 229.378L139.097 238.278C140.081 239.263 140.08 240.856 139.097 241.839Z"
          fill="#d4dbdf"
        />
        <path
          d="M106.419 206.163C107.14 206.162 107.854 206.303 108.52 206.578C109.186 206.854 109.791 207.258 110.3 207.767C110.81 208.277 111.214 208.882 111.489 209.548C111.764 210.214 111.905 210.927 111.904 211.648L111.904 224.023C111.908 225.586 111.291 227.088 110.188 228.196C109.085 229.305 107.586 229.93 106.023 229.933C104.459 229.937 102.958 229.32 101.849 228.217C100.741 227.114 100.116 225.616 100.112 224.052L100.115 206.178L106.419 206.163Z"
          fill="#2e26f2"
        />
        <path
          d="M88.3341 206.174L88.3305 213.093C88.331 214.547 88.909 215.942 89.9375 216.971C90.966 217.999 92.3608 218.577 93.8154 218.578L100.108 218.574L100.112 200.707L100.112 206.196L88.3341 206.174Z"
          fill="#459fff"
        />
        <path
          d="M94.223 194.185C92.662 194.188 91.1656 194.809 90.0618 195.913C88.958 197.017 88.3367 198.513 88.3341 200.074L88.3342 206.174L100.112 206.174L100.112 200.685L100.112 200.074C100.109 198.513 99.4881 197.017 98.3843 195.913C97.2804 194.809 95.7841 194.188 94.223 194.185Z"
          fill="#2b66fe"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          x="35"
          y="185.825"
          width="151.074"
          height="151.074"
          transform="rotate(-45 35 185.825)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default { name: 'Error' };
</script>
