<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="closed-eye-svg no-margin-svg"
  >
    <path
      :fill="fill"
      d="M10.3389 5.7403L13.345 8.74643L13.3593 8.58897C13.3593 7.00954 12.0758 5.72598 10.4963 5.72598L10.3389 5.7403Z"
    />
    <path
      :fill="fill"
      d="M10.4973 3.81734C13.1312 3.81734 15.2689 5.95505 15.2689 8.58899C15.2689 9.20454 15.1449 9.79145 14.9302 10.3306L17.7216 13.122C19.1626 11.9196 20.2983 10.364 20.9997 8.58899C19.3439 4.39951 15.2737 1.43154 10.4973 1.43154C9.16125 1.43154 7.88247 1.6701 6.69434 2.09955L8.75569 4.15611C9.29482 3.94618 9.88173 3.81734 10.4973 3.81734Z"
    />
    <path
      :fill="fill"
      d="M0.954313 1.21677L3.13018 3.39264L3.56442 3.82687C1.98977 5.05796 0.744376 6.69464 0 8.58896C1.651 12.7784 5.72597 15.7464 10.4976 15.7464C11.9769 15.7464 13.3892 15.4601 14.6824 14.94L15.088 15.3456L17.8699 18.1322L19.0866 16.9202L2.17108 0L0.954313 1.21677ZM6.23178 6.48945L7.70622 7.96389C7.66327 8.16908 7.63464 8.37423 7.63464 8.58896C7.63464 10.1684 8.9182 11.4519 10.4976 11.4519C10.7124 11.4519 10.9175 11.4233 11.118 11.3804L12.5924 12.8548C11.9577 13.1697 11.2516 13.3606 10.4976 13.3606C7.86368 13.3606 5.72597 11.2229 5.72597 8.58896C5.72597 7.83505 5.91685 7.12883 6.23178 6.48945Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#000',
    },
  },
};
</script>

 <style lang="scss" scoped>
  .closed-eye-svg {
    width: 21px;
    height: 17px;
  }
</style>
