<template>
  <div class="password-confirmation">
    <Password
      v-model="password"
      :placeholder="passwordPlaceholder"
      :errored="Boolean(password && !isPasswordValid)"
      :required="true"
      data-test="change-password__form--new_password"
      @input="validForm"
    />

    <Password
      v-model="confirmPassword"
      :placeholder="confirmPasswordPlaceholder"
      :errored="Boolean(confirmPassword && !validPasswordConfirmation)"
      :required="true"
      data-test="change-password__form--confirm_password"
      @input="validForm"
    />

    <p
      v-if="Boolean(password && !validPasswordConfirmation)"
      class="password-confirmation--warning"
    >
      {{ $t('warnings.passwords_not_identical') }}
    </p>

    <div class="password-confirmation__validation-container">
      <span
        v-for="element in passwordValidationElements"
        :key="element"
      >
        <p :class="passwordCheckClasses(element)">
          <component
            :is="passwordCheckIcon(element)"
            :fill="passwordCheckColor(element)"
          />
          {{ $t(`warnings.password.${element}`) }}
        </p>
      </span>
    </div>
  </div>
</template>

<script>
import {
  ClosingXIcon,
  CheckMarkIcon,
} from '@skelloapp/skello-ui';
import {
  isValidPasswordLength,
  isValidPasswordUpper,
  isValidPasswordLower,
  isValidPasswordDigit,
} from '@skello-utils/validators';
import Password from '../Password';

export default {
  name: 'PasswordConfirmation',
  components: {
    Password,
    ClosingXIcon,
    CheckMarkIcon,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    passwordPlaceholder: {
      type: String,
      required: true,
    },
    confirmPasswordPlaceholder: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      confirmPassword: '',
      passwordValidationElements: ['length', 'upper', 'lower', 'digit'],
    };
  },

  computed: {
    password: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    isPasswordValid() {
      return isValidPasswordLength(this.password) &&
        isValidPasswordUpper(this.password) &&
        isValidPasswordLower(this.password) &&
        isValidPasswordDigit(this.password);
    },
    validPasswordConfirmation() {
      return this.password === this.confirmPassword;
    },
  },
  methods: {
    validForm() {
      this.$nextTick(() => {
        const isValid = Boolean(this.isPasswordValid && this.validPasswordConfirmation);
        this.$emit('validation-updated', isValid);
      });
    },
    checkElementValidity(element) {
      switch (element) {
        case 'length':
          return isValidPasswordLength(this.password);
        case 'upper':
          return isValidPasswordUpper(this.password);
        case 'lower':
          return isValidPasswordLower(this.password);
        case 'digit':
          return isValidPasswordDigit(this.password);
        default:
          console.error(`${element} is not checked`);
          return false;
      }
    },
    passwordCheckIcon(element) {
      if (!this.password) return CheckMarkIcon;
      return this.checkElementValidity(element) ? CheckMarkIcon : ClosingXIcon;
    },
    passwordCheckClasses(element) {
      return {
        'confirmation-password__password--inactive': !this.password,
        'confirmation-password__password-check--valid': this.password && this.checkElementValidity(element),
        'confirmation-password__password-check--invalid': this.password && !this.checkElementValidity(element),
      };
    },
    passwordCheckColor(element) {
      if (!this.password) return '#727272';
      return this.checkElementValidity(element) ? '#188377' : '#d03e50';
    },
  },
};
</script>

<style lang="scss" scoped>
.password-confirmation {
  &--warning {
    color: $sk-error;
    font-size: $fs-text-s;
    padding-left: 12px;
    text-align: left;
    position: absolute;
    margin-top: -3px;
  }

  &__password--inactive {
    color: $sk-grey-50;
  }

  &__password-check--valid {
    color: $sk-success;
  }

  &__password-check--invalid {
    color: $sk-error;
  }

  &__validation-container {
    padding-top: 20px;
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 12px;
    font-family: Gellix;

    & > span {
      & > p {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 0;
        font-size: 12px;

        & > svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
