<template>
  <div id="error-page__wrapper">
    <div class="error-page__illustration">
      <NotFoundIcon v-if="formatErrorMessage.status === 404" />
      <ErrorIcon v-else />
    </div>
    <div class="error-page__text-content">
      <div class="error-page__header sk-header--1">
        {{ $t('errors.oops') }}
      </div>
      <div class="error-page__title sk-header--2">
        {{ $t('errors.error') }} {{ formatErrorMessage.status }}
      </div>
      <div class="error-page__description">
        {{ formatErrorMessage.title }}
      </div>
      <div class="back-btn">
        <SkOroraButton @click="goBackHome">
          {{ $t('general.back_home') }}
        </SkOroraButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ErrorIcon from './ErrorIcon';
import NotFoundIcon from './NotFoundIcon';

export default {
  name: 'ErrorPage',
  components: { ErrorIcon, NotFoundIcon },
  computed: {
    ...mapState(['setupComplete', 'errorObject']),

    formatErrorMessage() {
      const errorStatus = this.getErrorStatus();

      if (errorStatus === 404) {
        return {
          status: 404,
          title: this.$t('errors.not_found'),
        };
      }
      if (errorStatus === 500) {
        return {
          status: 500,
          title: this.$t('errors.standard_message'),
        };
      }

      // Other cases
      const translationScope = `errors.${errorStatus}`;
      const message = this.$t(translationScope);

      return {
        status: errorStatus,
        title:
          message === translationScope ?
            this.$t('errors.standard_message') :
            message,
      };
    },
  },

  methods: {
    goBackHome() {
      /* Changing location path to `/` delegates the responsibility to redirect
       * to the correct path based on whether the user is logged in to the
       * backend. This cannot be done with vue-router because `/` is not a
       * registered route.
       */
      window.location.href = '/';
    },

    getErrorStatus() {
      const errorFrom = this.$route.query.status !== undefined ? 'v2' : 'v3';
      let errorStatus = null; // default error status

      if (errorFrom === 'v3') {
        errorStatus = this.errorObject?.data?.status ?? 404;
      } else {
        errorStatus = parseInt(this.$route.query.status, 10);
      }
      return errorStatus;
    },
  },

};
</script>

<style lang="scss" scoped>
#error-page__wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f9fb;

  .error-page__illustration {
    background-color: white;
    border-radius: 50%;
  }

  .error-page__text-content {
    padding-left: 165px;
    text-align: left;
  }

  .error-page__header {
    font-weight: bold;
    font-size: 120px;
    line-height: 180px;
    margin-left: -5px;
  }

  .error-page__title {
    font-weight: bold;
    font-size: $fs-heading-l;
    line-height: 39px;
  }

  .error-page__description {
    font-size: $fs-text-l;
    line-height: 26px;
    color: $sk-grey;
  }

  .back-btn {
    margin-top: 40px;
  }
}
</style>
