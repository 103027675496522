<template>
  <div
    v-if="showBanner"
    :class="bannerClass"
  >
    <div class="smart-app-banner__main-content">
      <CrossV2Icon
        fill="black"
        :height="crossSize"
        :width="crossSize"
        @click.native="hideBanner"
      />
      <div :class="logoClass">
        <SkColorSLogo
          :width="logoWidth"
          :height="logoHeight"
        />
      </div>
      <div :class="textClass">
        <div :class="titleClass">
          {{ $t('smart_app_banner.title') }}
        </div>
        <div :class="descClass">
          {{ $t('smart_app_banner.description') }}
        </div>
      </div>
    </div>
    <SkOroraButton
      :class="buttonClass"
      :size="size"
      @click="useApp"
    >
      {{ $t('smart_app_banner.use_app') }}
    </SkOroraButton>
  </div>
</template>

<script>
import {
  CrossV2Icon,
  SkOroraButton,
} from '@skelloapp/skello-ui';
import { SkColorSLogo } from '@branding';

const BANNER_SIZE = ['small', 'large'];

export default {
  name: 'SmartAppBanner',
  components: { SkOroraButton, CrossV2Icon, SkColorSLogo },
  props: {
    size: {
      type: String,
      default: 'small',
      validator: value => BANNER_SIZE.includes(value),
    },
  },
  data() {
    return {
      dismissed: false,
    };
  },
  computed: {
    showBanner() {
      return localStorage.getItem('smart-app-banner-dismissed') !== 'true' && !this.dismissed;
    },
    crossSize() {
      return this.size === 'large' ? '32' : '16';
    },
    logoHeight() {
      return this.size === 'large' ? '50' : '25';
    },
    logoWidth() {
      return this.size === 'large' ? '30' : '15';
    },
    textClass() {
      return {
        'smart-app-banner__text': true,
        'smart-app-banner__text--large': this.size === 'large',
      };
    },
    bannerClass() {
      return {
        'smart-app-banner': true,
        'smart-app-banner--large': this.size === 'large',

      };
    },
    logoClass() {
      return {
        'smart-app-banner__logo': true,
        'smart-app-banner__logo--large': this.size === 'large',
      };
    },
    titleClass() {
      return {
        'smart-app-banner__text-title': true,
        'smart-app-banner__text-title--large': this.size === 'large',
      };
    },
    descClass() {
      return {
        'smart-app-banner__text-desc': true,
        'smart-app-banner__text-desc--large': this.size === 'large',
      };
    },
    buttonClass() {
      return {
        'smart-app-banner__button--large': this.size === 'large',
      };
    },
  },
  methods: {
    useApp() {
      // deeplinking generate by firebase dynamic link
      window.location.href = 'https://skello.page.link/vn1s';
    },
    hideBanner() {
      this.dismissed = true;
      localStorage.setItem('smart-app-banner-dismissed', 'true');
    },
  },
};
</script>

<style lang="scss" scoped>
.smart-app-banner {
  min-height: 47px;
  background: $sk-blue-5;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  z-index: 100000;
  position: sticky;
  top: 0;

  // special overide here, the banner is really small when you are logged in
  // so we need to make everything bigger
  ::v-deep .sk-button--large  {
    font-size: 32px;
    height: 66px;
    border-radius: 44px;
    padding: 0px 26px;
  }

  &--large {
    min-height: 94px;
    padding-left: 24px;
    padding-right: 16px;
  }
}

.smart-app-banner__main-content {
  display: flex;
  align-items: center;
}

.smart-app-banner__logo {
  width: 38px;
  height: 38px;
  background-color: $sk-white;
  border-radius: 8.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  &--large {
    width: 76px;
    height: 76px;
    margin-left: 24px;
  }
}

.smart-app-banner__text {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  line-height: 1.3;

  &--large {
    margin-left: 16px;
  }
}

.smart-app-banner__text-title {
  font-size: 14px;
  font-weight: $fw-semi-bold;
  color: $sk-black;

  &--large {
    font-size: 32px;
  }
}

.smart-app-banner__text-desc {
  font-size: 12px;
  color: $sk-black;

  &--large {
    font-size: 24px;
  }
}
</style>
