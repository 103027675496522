<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="420"
    height="420"
    viewBox="0 0 506 420"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="237"
      cy="210"
      r="210"
      fill="white"
    />
    <g filter="url(#filter0_d_109_2)">
      <rect
        x="64"
        y="150.406"
        width="343"
        height="116.239"
        rx="10"
        fill="#FBFBFB"
      />
      <rect
        x="65"
        y="151.406"
        width="341"
        height="114.239"
        rx="9"
        stroke="#F1F0F0"
        stroke-width="2"
        stroke-dasharray="7 7"
      />
    </g>
    <circle
      cx="337.245"
      cy="179.141"
      r="9.78203"
      fill="#F1F0F0"
    />
    <rect
      x="363.982"
      y="169.359"
      width="31.1618"
      height="20.0326"
      rx="1.70658"
      fill="#F1F0F0"
    />
    <path
      d="M77.8928 216.684C77.8928 214.799 79.4209 213.271 81.306 213.271H348.902C350.788 213.271 352.316 214.799 352.316 216.684V250.347C352.316 252.232 350.788 253.76 348.902 253.76H81.306C79.421 253.76 77.8928 252.232 77.8928 250.347V216.684Z"
      fill="black"
      fill-opacity="0.15"
    />
    <path
      d="M77.8928 216.684C77.8928 214.799 79.4209 213.271 81.306 213.271H348.902C350.788 213.271 352.316 214.799 352.316 216.684V250.347C352.316 252.232 350.788 253.76 348.902 253.76H81.306C79.421 253.76 77.8928 252.232 77.8928 250.347V216.684Z"
      fill="#F1F0F0"
    />
    <path
      d="M78.2915 172.874C78.2915 170.989 79.8196 169.461 81.7047 169.461H238.756C240.641 169.461 242.169 170.989 242.169 172.874V186.056C242.169 187.941 240.641 189.469 238.756 189.469H81.7047C79.8196 189.469 78.2915 187.941 78.2915 186.056V172.874Z"
      fill="black"
      fill-opacity="0.15"
    />
    <path
      d="M78.2915 172.874C78.2915 170.989 79.8196 169.461 81.7047 169.461H238.756C240.641 169.461 242.169 170.989 242.169 172.874V186.056C242.169 187.941 240.641 189.469 238.756 189.469H81.7047C79.8196 189.469 78.2915 187.941 78.2915 186.056V172.874Z"
      fill="#F1F0F0"
    />
    <rect
      x="373"
      y="271.607"
      width="12.0314"
      height="30.8628"
      transform="rotate(-27.7746 373 271.607)"
      fill="#000000"
    />
    <rect
      x="378"
      y="292.073"
      width="22"
      height="116.966"
      rx="11"
      transform="rotate(-27.2502 378 292.073)"
      fill="#000000"
    />
    <path
      d="M383.037 301.852C380.255 296.452 382.378 289.818 387.779 287.037C393.18 284.255 399.813 286.378 402.595 291.779L406.716 299.78L387.158 309.854L383.037 301.852Z"
      fill="#9EA7A9"
    />
    <circle
      cx="348.431"
      cy="207.092"
      r="65.8667"
      transform="rotate(-27.1698 348.431 207.092)"
      fill="#9EA7A9"
      stroke="#D4DBDF"
      stroke-width="11"
    />
    <mask
      id="mask0_109_2"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="288"
      y="146"
      width="122"
      height="123"
    >
      <path
        d="M389.949 165.165C413.463 187.982 414.132 225.41 391.472 248.763C368.812 272.115 331.38 272.57 307.866 249.753C284.353 226.935 283.683 189.507 306.344 166.155C329.004 142.803 366.435 142.347 389.949 165.165Z"
        fill="#56626C"
        stroke="#56626C"
        stroke-width="3"
      />
    </mask>
    <g mask="url(#mask0_109_2)">
      <g filter="url(#filter1_d_109_2)">
        <path
          d="M58.4301 154.775C58.4301 149.12 63.0144 144.535 68.6695 144.535H419.759C425.414 144.535 429.999 149.12 429.999 154.775V259.761C429.999 265.416 425.414 270 419.759 270H68.6696C63.0144 270 58.4301 265.416 58.4301 259.761V154.775Z"
          fill="#FEBF3F"
        />
        <path
          d="M405.456 179.187C405.456 180.302 405.069 181.235 404.296 181.986C403.522 182.737 402.566 183.112 401.429 183.112C400.518 183.112 399.694 182.828 398.954 182.259C398.215 181.69 397.725 180.922 397.486 179.955L398.886 179.392C399.056 180.074 399.364 180.615 399.807 181.013C400.262 181.411 400.791 181.61 401.394 181.61C402.123 181.61 402.714 181.388 403.169 180.945C403.636 180.49 403.869 179.909 403.869 179.204C403.869 178.487 403.636 177.901 403.169 177.446C402.714 176.991 402.128 176.763 401.412 176.763C400.581 176.763 399.927 177.099 399.449 177.77L397.862 177.088L398.613 171.166H404.739V172.668H399.927L399.5 176.013L399.568 176.03C400.149 175.563 400.854 175.33 401.685 175.33C402.731 175.33 403.619 175.7 404.347 176.439C405.086 177.179 405.456 178.095 405.456 179.187ZM407.226 170.62H408.796V174.477L408.727 175.637H408.796C409.035 175.228 409.399 174.886 409.888 174.613C410.389 174.34 410.906 174.204 411.441 174.204C412.465 174.204 413.25 174.499 413.796 175.091C414.353 175.671 414.632 176.502 414.632 177.583V182.839H413.062V177.89C413.062 176.388 412.397 175.637 411.065 175.637C410.428 175.637 409.888 175.904 409.444 176.439C409.012 176.963 408.796 177.577 408.796 178.282V182.839H407.226V170.62Z"
          fill="white"
        />
        <circle
          cx="355.546"
          cy="175.9"
          r="12.0639"
          fill="#FFDF9F"
        />
        <rect
          x="381.739"
          y="166.251"
          width="33.779"
          height="21.715"
          rx="1.70658"
          fill="#FFDF9F"
        />
        <rect
          x="76.3876"
          y="212.104"
          width="291.269"
          height="42.9239"
          rx="6"
          fill="#D8A236"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_109_2"
        x="0"
        y="118.406"
        width="471"
        height="244.239"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="32" />
        <feGaussianBlur stdDeviation="32" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.829167 0 0 0 0 0.829167 0 0 0 0 0.829167 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_109_2"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_109_2"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_109_2"
        x="42.1284"
        y="128.234"
        width="420.474"
        height="174.37"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset
          dx="8.15084"
          dy="8.15084"
        />
        <feGaussianBlur stdDeviation="12.2263" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_109_2"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_109_2"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default { name: 'NotFound' };
</script>
